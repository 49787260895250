/* globally */
html {
  scroll-behavior: smooth;
}
img {
  /* aspect-ratio: attr(width) / attr(height); */
  /* aspect-ratio: 16 / 9; */
  aspect-ratio: auto;
  object-fit: contain;
}
/* font-waits */
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

/* Button Arrows Hover effect */
.btn-arrow-right {
  position: relative;
}
.btn-arrow-right > .MuiButton-label::after {
  font-family: "Material Icons";
  font-size: 24px;
  color: inherit;
  content: "\e317";
  transform: rotate(180deg);
  position: relative;
  height: 0px;
  line-height: 0;
  right: -8px;
  display: inline-block;
  transition: 0.5s ease-in-out;
}
/* .btn-arrow-right:hover > .MuiButton-label::after {
  right: -20px;
} */
.btn-arrow-down > .MuiButton-label::after {
  font-family: "Material Icons";
  font-size: 20px;
  color: inherit;
  content: "\e5db";
  position: relative;
  height: 0px;
  line-height: 0;
  right: -8px;
  display: inline-block;
  transition: 0.5s ease-in-out;
}
/* .btn-arrow-down:hover > .MuiButton-label::after {
  animation-name: arrow-down;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
} */

/* The animation code */
@keyframes arrow-down {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}
/* Position Utilities */
.position-relative {
  position: relative !important;
}

/* ellipsis */
.textEllipsis__one {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
.textEllipsis__two {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}
.textEllipsis__three {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}
.textEllipsis__four {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
}
.textEllipsis__five {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
}
.textEllipsis__six {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  display: -webkit-box;
}
/*.makeStyles-content-3{
  margin-top :60px;
}
@media screen and (max-width: 600px) {
  .offer_banner{
    position: relative !important;
    height: 45px !important;
    margin-top: -20px!important;
  }
}
.jss3{
  padding-top: 35px !important;
}*/
.jss43 li a,
.makeStyles-menuList-43 li a {
  padding: 5px 10px !important;
}
h3 {
  font-weight: 500 !important;
}
strong,
b {
  font-weight: 500 !important;
}
